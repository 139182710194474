<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row style="padding-left:10px">
          <v-col> 
            <h3>
            مدیریت {{ label }}             
            </h3>
            </v-col>
          <v-col>
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="gotoNewService()"
            >
            <v-icon left>add</v-icon>
           ثبت جدید
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row align-v="end">
              <v-col> </v-col>
              <v-col>
                <v-btn
                  @click="$refs.servicesExcel.$el.click()"
                  style="float: left"
                  class="secondary-btn mb-2"
                  >دریافت اکسل</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="servicesExcel"
                  :data="Items"
                  :columns="excelFields"
                  :filename="'لیست ' + label"
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              clearable
              rounded
              class="text-right w-80 mb-2 search-input"
              @filtered="onFiltered"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="fields"
              :items="Items"
              empty-text="سرویسی برای نمایش وجود ندارد"
              empty-filtered-text="سرویسی برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn class="primary-btn pa-2" :to="edit + data.item.id"
                    ><v-icon>
                      edit</v-icon></v-btn
                  >
                </div>
              </template>
              <template v-slot:cell(cost)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";

export default {
  props: ["label", "get", "new", "edit", "fields", "excelFields"],
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD")
    };
  },

  methods: {
    gotoNewService() {
      this.$router.push(this.new);
    },
    showServiceInfo() {
      //NOTE getting all services info when page mount
      this.$http
        .post(
          this.baseUrl + this.get,
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    }
  },

  mounted() {
    this.showServiceInfo();
  }
};
</script>
